import React, { useContext } from 'react';
import Account from './Account';
import CifSection from './CifSection';
import { StoreContext } from '../../store/store';
import './Account.scss';
import { SPLASH_LOAD } from '../../constants/actionTypes';
import { ServiceRequest } from '../../utils/Services';
import analyticsTealium from '../../utils/Analytics/analytics';
import VFLogger from '../../utils/Logger';
import { getSmapiPageName } from '../../constants/smapiMapper';

const AccountSelection = (props) => {
  let fields = [];
  let { state, dispatch } = useContext(StoreContext);
  const { trackPageEvent } = analyticsTealium();

  if (state.data.fields !== undefined) {
    let accounts = undefined;

    if (state.data.fields.soho_accounts !== undefined && state.data.fields.soho_accounts[0] !== undefined) {
      //Soho Account
      accounts = state.data.fields.soho_accounts[0];
    } else {
      accounts = state.data.fields.accounts[0];
    }
    //Add Account Selection
    if (accounts.type === 'account') {
      accounts.addressList.map(function (account, i) {
        let address_details = (
          <Account key={i} account={account} onAccountSelectHandler={accountSelectionHandler}></Account>
        );
        fields.push(address_details);
      });
      //Add Account Selection - CIF records
    } else if (accounts.type === 'soho_account') {
      let cif_id = Object.keys(accounts.cifAccounts);
      let i = 0;
      let j = Math.random();
      Object.keys(accounts.cifAccounts).forEach(function (prop) {
        fields.push(<CifSection key={i} cif={cif_id[i]}></CifSection>);
        Object.keys(accounts.cifAccounts[prop]).forEach(function (sprop) {
          let address_details = (
            <Account
              key={i + j}
              account={accounts.cifAccounts[prop][sprop]}
              cif={cif_id[i]}
              onAccountSelectHandler={accountSelectionHandler}
            ></Account>
          );
          fields.push(address_details);
          j++;
        });
        i++;
      });
    }
  }

  //Account Section Handler
  function accountSelectionHandler(id) {
    let accountValue, cif;
    let jsonObj = '';

    if (id) {
      accountValue = id;
    }

    if (accountValue !== undefined && accountValue.indexOf('_') > 0) {
      accountValue = accountValue.split('_');
      cif = accountValue[1];
      accountValue = accountValue[0];
    }

    if (state.data.links['urn:vodafoneid:accountSelection'].data) {
      jsonObj = {
        accountId: accountValue,
        cif: cif,
        nif: state.data.links['urn:vodafoneid:accountSelection'].data.nif,
        email: state.data.links['urn:vodafoneid:accountSelection'].data.email,
        marketCreationDate: state.data.links['urn:vodafoneid:accountSelection'].data.marketCreationDate,
        phoneNumber: state.data.links['urn:vodafoneid:accountSelection'].data.phoneNumber,
        contactId: state.data.links['urn:vodafoneid:accountSelection'].data.contactId,
        docType: state.data.links['urn:vodafoneid:accountSelection'].data.docType,
      };
    }

    let logInfo = {
      page: state.data.context,
    };

    //Track the page view event in tealium
    trackPageEvent(state.data.context, { eventCategory: 'link', eventLabel: 'Account Select Button' }),
      dispatch({
        type: SPLASH_LOAD,
        splash: true,
      });
    VFLogger.info(`${getSmapiPageName(state.data.context)}: click on account selection link`);
    ServiceRequest(
      state.data.links['urn:vodafoneid:accountSelection'],
      JSON.stringify(jsonObj),
      props.RequestCallBack,
      null,
      logInfo
    );
  }

  return (
    <>
      <div className="section-form">
        <div className="box">{fields}</div>
      </div>
    </>
  );
};

export default AccountSelection;
