import React, { useEffect, useContext } from 'react';
import { text } from '../../utils/global';

import { StoreContext } from '../../store/store';

// Action types
import { BANNER, POPUP } from '../../constants/actionTypes';

import Banner from '../Banner/Banner';
import Button from '../Button/Button';
import Link from '../Link/Link';
import cookieConsent from '../../utils/cookieConsent';

const CookieBanner = () => {
  const globalState = useContext(StoreContext);
  const { dispatch } = globalState;

  const { saveCookieConsent, checkShowBanner } = cookieConsent();

  // Translations
  let bannerText = <>{text('cookieManager.cookieBanner.instruction')}</>;
  let bannerTextMobile = <>{text('cookieManager.cookieBanner.instructionMobile')}</>;

  // if(window.privacyPolicy){
  bannerText = (
    <>
      {text('cookieManager.cookieBanner.instruction')}&nbsp;
      <Link label={'Cookie Policy'} handleLink={() => showPrivacyPolicy()} />
    </>
  );
  bannerTextMobile = (
    <>
      {text('cookieManager.cookieBanner.instructionMobile')}&nbsp;
      <Link label={'Cookie Policy'} handleLink={() => showPrivacyPolicy()} />
    </>
  );
  // }

  let manageLinkLabel = text('cookieManager.aboutCookieLink');
  let acceptBtnLabel = text('cookieManager.cookieBanner.okButton');
  let showRejectBtn = true;

  if (
    !window.config.smapiEnabled &&
    window.config.analytics &&
    !window.config.analytics.tealium_enabled &&
    window.config.cookieManagement &&
    !window.config.cookieManagement.functionalCookiesEnabled
  ) {
    manageLinkLabel = text('cookieManager.cookieBanner.viewCookies');
    acceptBtnLabel = text('cookieManager.cookieBanner.dismiss');
    showRejectBtn = false;
  }

  useEffect(() => {
    return () => {};
  }, []);

  const handleManageCookies = () => {
    dispatch({
      type: POPUP,
      popup: 'MANAGE_COOKIES',
    });
  };

  const handleRejectCookies = () => {
    //Save cookie consent to false
    saveCookieConsent(false);
    closeBanner();
  };

  const handleAcceptCookies = () => {
    //Save cookie consent to true
    saveCookieConsent(true);
    closeBanner();
  };

  const closeBanner = () => {
    dispatch({
      type: BANNER,
      banner: false,
    });
  };

  const showPrivacyPolicy = () => {
    dispatch({
      type: POPUP,
      popup: 'COOKIE_POLICY',
    });
  };

  return (
    <>
      {globalState.state.banner && checkShowBanner() && (
        <Banner text={bannerText} textMobile={bannerTextMobile}>
          <Button
            type={'secondary'}
            id="manageCookieButton"
            label={manageLinkLabel}
            handleClick={handleManageCookies}
          />
          {showRejectBtn && (
            <Button
              type={'primary'}
              id="rejectCookieButton"
              label={text('cookieManager.cookieBanner.rejectButton')}
              handleClick={handleRejectCookies}
            />
          )}
          <Button type={'primary'} id="acceptCookieButton" label={acceptBtnLabel} handleClick={handleAcceptCookies} />
        </Banner>
      )}
    </>
  );
};
export default CookieBanner;
