import cookieConsent from '../utils/cookieConsent';

const VFLogger = {
  client_id: 'NA',
  auth_id: 'NA',
  arr: [],
  setClientID: function (client_id) {
    this.client_id = client_id;
  },
  setAuthID: function (auth_id) {
    this.auth_id = auth_id;
  },
  push: function () {
    if (arguments.length > 1) {
      if (arguments[0] !== 'log' && arguments[0] !== 'error' && arguments[0] !== 'info') {
        if (process.env.REACT_APP_DEBUG_MODE)
          console.warn('// VFLogger.push 1st argument needs to be either log, error or info.');
        return;
      }
      var ts = new Date().getTime();
      if (process.env.REACT_APP_DEBUG_MODE) console.warn('event stored with timestamp: ', ts);
      var tmp = [ts];
      for (var i = 0; i < arguments.length; i++) {
        tmp.push(arguments[i]);
      }
      this.arr.push(tmp);
    } else {
      if (process.env.REACT_APP_DEBUG_MODE)
        console.warn(
          '// VFLogger.push needs at least 2 arguments to build the message [type, title [,args_msg_body] ].'
        );
    }
  },
  flush: function () {
    for (var i = 0; i < this.arr.length; i++) {
      this.writeMessage(this.arr[i][1], this.arr[i].slice(2), this.arr[i][0]);
    }
    this.arr = [];
  },
  smapi: function (o) {
    let eventElement = o.msg === '' ? 'NA' : o.msg;
    let eventDescription = o.title;
    let onPage = process.env.REACT_APP_ENV.toUpperCase() + ' - ' + window.location.href + ' - ' + o.page;
    if (window.submitter && navigator.cookieEnabled && window.config.smapiEnabled) {
      // Last param in logCustomEvent is customjson object
      let customJson = {
        'authorization-id': window.getAuthId() != 'NA' ? window.getAuthId() : this.auth_id,
        market: window.config ? window.config.market.locale.split('_')[1] : 'NA',
        'service-id': window.service_id ? window.service_id : 'NA',
      };
      window.submitter.logCustomEvent(eventElement, eventDescription, onPage, '', customJson);
    }
  },
  writeMessage: function (type, args, ts) {
    const { getCookieConsent } = cookieConsent();
    var title = args[0];
    var str = '';
    var pageName = '';

    if (args.length > 1) {
      for (var i = 1; i < args.length; i++) {
        if (typeof args[i] === 'object') {
          //str += ' '+JSON.stringify(args[i]);
          if (args[i] !== null && Object.prototype.hasOwnProperty.call(args[i], 'page')) {
            pageName = args[i].page;
          }
        } else {
          str += ' ' + String(args[i]);
        }
      }
    }
    if (
      window.config &&
      window.config.smapiEnabled &&
      getCookieConsent('performance') &&
      (type === 'info' || type === 'warn')
    ) {
      var o = {
        title: title,
        msg: str,
        level: type,
        ts: typeof ts !== 'undefined' ? ts : new Date().getTime(),
        page: pageName,
      };
      this.smapi(o);
    }
  },
  log: function () {
    if (arguments.length > 0) {
      this.writeMessage('log', arguments);
    } else {
      if (process.env.REACT_APP_DEBUG_MODE)
        console.warn('// VFLogger.log needs at least 1 argument to build the message.');
    }
  },
  client: function () {
    if (arguments.length > 0) {
      this.writeMessage('client', arguments);
    } else {
      if (process.env.REACT_APP_SHOW_LOGS)
        console.warn('VFLogger.info needs at least 1 argument to build the message.');
    }
  },
  request: function () {
    if (arguments.length > 0) {
      this.writeMessage('request', arguments);
    } else {
      if (process.env.REACT_APP_SHOW_LOGS)
        console.warn('VFLogger.info needs at least 1 argument to build the message.');
    }
  },
  internal: function () {
    if (arguments.length > 0) {
      this.writeMessage('internal', arguments);
    } else {
      if (process.env.REACT_APP_SHOW_LOGS)
        console.warn('VFLogger.info needs at least 1 argument to build the message.');
    }
  },
  info: function () {
    if (arguments.length > 0) {
      this.writeMessage('info', arguments);
    } else {
      if (process.env.REACT_APP_DEBUG_MODE)
        console.warn('// VFLogger.log needs at least 1 argument to build the message.');
    }
  },
  warn: function () {
    if (arguments.length > 0) {
      this.writeMessage('warn', arguments);
    } else {
      if (process.env.REACT_APP_DEBUG_MODE)
        console.warn('// VFLogger.warn needs at least 1 argument to build the message.');
    }
  },
  error: function () {
    if (arguments.length > 0) {
      this.writeMessage('error', arguments);
    } else {
      if (process.env.REACT_APP_DEBUG_MODE)
        console.warn('// VFLogger.error needs at least 1 argument to build the message.');
    }
  },
};
export default VFLogger;
