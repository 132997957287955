import React, { useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { StoreContext } from '../../store/store';
import ServiceRequest from '../../utils/Services';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SectionTitle from '../SectionTitle/SectionTitle';
import FormErrorBox from '../FormErrorBox/FormErrorBox';
import { text, extractUrlParameter } from '../../utils/global';
import buildAuthUrl from '../../utils/buildAuthUrl.js';

import Error from '../Error/Error.js';

import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import ManageCookies from '../ManageCookies/ManageCookies';
import CookieBanner from '../CookieBanner/CookieBanner';

import { RESPONSE_SUCCESS, RESPONSE_ERROR, RESPONSE_ERROR_CLEAR, INITIAL_ERROR } from '../../constants/actionTypes';

import SessionKiller from '../Session/SessionKiller';

import Link from '../Link/Link';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner.js';

import { configRequest, getAuthRequest } from '../../utils/Services';
import { setConfigs } from '../../utils/initialConfigs.js';
import analyticsTealium from '../../utils/Analytics/analytics';
import cookieConsent from '../../utils/cookieConsent';
import { getPageName } from '../../constants/tealiumMapper.js';
import getContextRender from './getContextRender.js';
import useSMAPI from '../SMAPI/useSMAPI.js';
import VFLogger from '../../utils/Logger';
import Follow from '../Follow/Follow.js';

export const AuthorizeMain = () => {
  const authUrl = buildAuthUrl();
  const { initiateSmapiOnLoad, loadSMAPI } = useSMAPI();
  const { state, dispatch } = useContext(StoreContext);
  const { initializeTealium, trackPageView, trackPageEvent } = analyticsTealium();
  const { initializeCookieConsent } = cookieConsent();

  let contextRender = false,
    backendMsg = false,
    title = false,
    subTitle = false,
    titleLogo = false,
    sessionTimer = false,
    context = '';
  let inputErr = '';

  useEffect(() => {
    async function initialRequest() {
      try {
        const configs = await configRequest(dispatch);
        await setConfigs(configs, dispatch);
        if (window.config && window.config.smapiEnabled) {
          await loadSMAPI();
          await initiateSmapiOnLoad(true);
        }
        initializeTealium();
        initializeCookieConsent(dispatch);
        const responseData = await getAuthRequest(authUrl, dispatch);
        RequestCallBack(responseData);
      } catch (e) {
        console.log(e);
        let errorObj = {
          data: 'oops.message',
          status: 500,
        };
        RequestCallBack(errorObj);
      }
    }
    initialRequest();
  }, []);

  function RequestCallBack(response) {
    console.log('Response to callback=', response, response.status);
    if (response !== undefined && response.status === 200) {
      //Track the page view event in tealium
      trackPageView(getPageName(response.data.context), { flowName: 'login', flowType: 'login' });

      // attach service name to window object
      window.service_name =
        response.data.context === undefined || response.data.context === ''
          ? ''
          : response.data.clientInfo === undefined || response.data.clientInfo === null
          ? ''
          : response.data.clientInfo.client_name;

      dispatch({
        type: RESPONSE_SUCCESS,
        payload: response.data,
      });
    } else {
      let statusText = 'oops.message';
      let keyArgs = undefined;
      if (response !== '' && response.data.key !== undefined) {
        statusText = response.data.key;
      }
      if (response !== '' && response.data.arguments !== undefined) {
        keyArgs = response.data.arguments;
      }
      dispatch({
        type: RESPONSE_ERROR,
        payload: {
          status: response.status,
          key: statusText,
          arguments: keyArgs,
        },
      });
    }
  }

  const clearErrorMessage = () => {
    dispatch({
      type: RESPONSE_ERROR_CLEAR,
      payload: {},
    });
  };

  if (state.data !== undefined && state.data !== null && state.data.context !== undefined) {
    VFLogger.log(state);
    window.context = state.data;
    context = state.data.context.toLowerCase();
    contextRender = getContextRender(state.data.context, ServiceRequest, RequestCallBack, state);

    // Service Logo
    if (
      state.data.context !== 'CONFIRM_PIN' &&
      state.data.context !== 'END_FLOW_LOADING' &&
      state.data.isPwdResetFlow !== true
    ) {
      titleLogo = '';
    }

    // Title and Sub Title
    if (
      state.data.context !== 'CONFIRM_PIN' &&
      state.data.context !== 'END_FLOW_LOADING' &&
      state.data.context !== 'END_FLOW' &&
      state.data.context !== 'NON_VODAFONE_ENABLED' &&
      state.data.context !== 'UPDATE_PROFILE'
    ) {
      let contextName = state.data.context;
      if (contextName === 'END_FLOW_LOADING') {
        contextName = 'CONFIRM_PIN';
      }
      title = 'page.title.for.' + contextName.toLowerCase() + '.page';
      // As per new design subtitle should not be there
      // subTitle = 'page.intro.for.'+ contextName +'.page';
    }

    // Logo, Title and subTitle for Confirm Pin success flow
    if (state.data.context === 'CONFIRM_PIN') {
      title = 'page.title.for.confirm_pin.page';
      //subTitle = 'page.subtitle.for.password.reset.success.page';
      context = '';
    }

    // Logo, Title and subTitle for Password reset success flow
    if (state.data.isPwdResetFlow === true && state.data.context === 'END_FLOW') {
      title = 'page.title.for.password.reset.success.page';
      subTitle = 'page.subtitle.for.password.reset.success.page';
      context = '';
    }

    // Logo, Title and  subTitle for Success Registration flow
    if (state.data.context === 'END_FLOW_SUCCESS') {
      title = 'page.title.for.registration.success.page';
      subTitle = 'page.subtitle.for.registration.success.page';
      context = '';
    }

    // Logo, Title and subTitle for OneApp
    if (state.data.context === 'AUTHENTICATE' && state.page_look === 'mva') {
      subTitle = 'page.subtitle.for.authenticate.page';
      context = '';
    }

    // Backend Messages
    // if((state.data.message && state.data.context !== 'CONFIRM_PIN' && state.data.context !== 'END_FLOW_LOADING') || (state.errorObj && state.errorObj.msg)){
    if (
      (state.errorObj && state.errorObj.msg) ||
      (state.data.context === 'END_FLOW' && state.data.message !== undefined)
    ) {
      // if((state.errorObj && state.errorObj.msg)){

      let errorMsg = '';
      let msgType = '';
      if (state.errorObj.msg) {
        // Check if Message is text or key
        if (state.errorObj.text === 'true') {
          errorMsg = state.errorObj.msg;
        } else {
          errorMsg = text(state.errorObj.msg, state.errorObj.arguments);
        }
        msgType = state.errorObj.type;
      } else {
        // Check if Message is text or key
        if (state.errorObj.text === 'true') {
          errorMsg = state.data.message.value;
        } else {
          errorMsg = text(state.data.message.value, state.data.message.arguments);
        }
        // inputErr = 'input_error';
        msgType = state.data.message.type;
      }

      console.log('window.config', window.config);
      if (state.page_look === 'mva') {
        if (state.data.context === 'END_FLOW') {
          backendMsg = (
            <div className="vf-error-wrapper">
              <div className="vf-error">
                <div className="vf-error-icon-wrapper">
                  <div className="vf-error-icon"></div>
                </div>
                <div className="vf-error-title">
                  {state.data.message && state.data.message.type === 'error' ? text('oops.intro') : ''}
                </div>
                <div className="vf-error-text">
                  {state.data.message ? text(state.data.message.value) : text('oops.message')}
                </div>
              </div>
            </div>
          );
        } else {
          backendMsg = (
            <div className="toast-message-overlay">
              <div className="toast-message error">
                <div className="toast-message-icon"></div>
                <div className="toast-message-title">{text('incorrect.details.error.message.title')}</div>
                <div className="toast-message-text">
                  <p>{text('incorrect.details.error.message')}</p>

                  {/* <p>We couldn’t recognise your phone number or password. Please try again.</p>
                                            <p>If you can’t remember your password, use the reset password link below.</p> */}
                </div>
                <div className="toast-message-controls">
                  <Link
                    id="forgotPassword"
                    label={'Reset password'}
                    handleLink={() => {
                      trackPageEvent(getPageName('AUTHENTICATE_WRONG_CREDENTIALS'), {
                        eventCategory: 'link',
                        eventLabel: 'Reset password',
                      }),
                        ServiceRequest(state.data.links['urn:vodafoneid:resetpassword'], null, null, 'self');
                    }}
                  ></Link>

                  <Button
                    type={'primary'}
                    label={'Try again'}
                    id={'try-again'}
                    handleClick={() => {
                      trackPageEvent(getPageName('AUTHENTICATE_WRONG_CREDENTIALS'), {
                        eventCategory: 'button',
                        eventLabel: 'Try again',
                      }),
                        clearErrorMessage();
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
      } else {
        // This is normally error from form side, no 500 error
        backendMsg = (
          <FormErrorBox
            messageClass="form-error"
            error={errorMsg}
            iconType={`icon_${msgType}`}
            messageType={`message_${msgType}`}
          />
        );
      }
    }

    // Session Timer
    if (window.config !== undefined && state.data.isAuthenticated === false) {
      // Check Auth cookie is exist or not
      if (Cookies.get('authState')) {
        sessionTimer = (
          <SessionKiller sessionURL={state.data.links['urn:vodafoneid:sessionTimeout'].href}></SessionKiller>
        );
      } else {
        var sec =
          process.env.REACT_APP_SESSION_TIMEOUT_IN_SEC !== undefined ? process.env.REACT_APP_SESSION_TIMEOUT_IN_SEC : 0;
        if (sec > 0) {
          var expiry_time = new Date().getTime() + sec * 1000;
          var currentTime = new Date().getTime();
          var data = {
            epochExpTime: expiry_time,
            currentTime: currentTime,
            'error-url': state.data.links['urn:vodafoneid:sessionTimeout'].href,
          };
          Cookies.set('authState', btoa(JSON.stringify(data)), { path: '/' });
        }
      }
    }
  }

  console.log('state', state);
  if(state.data && state.data.context === "FOLLOW") {
    return <Follow />
  }
  if (state.isLoading) {
    return <Spinner />;
  }

  return (
    <div className={'outer-wrapper ' + state.page_look}>
      <Header />
      {state.popup && (
        <>
          {state.popup === 'TERMS_AND_CONDITIONS' ? (
            <TermsAndConditions />
          ) : state.popup === 'PRIVACY_POLICY' || state.popup === 'COOKIE_POLICY' ? (
            <PrivacyPolicy />
          ) : state.popup === 'MANAGE_COOKIES' ? (
            <ManageCookies />
          ) : (
            <></>
          )}
        </>
      )}
      <CookieBanner />
      <div className="main-wrapper">
        <div className="wrapper-inner">
          {state.isError && state.errorObj && state.errorObj.msg && state.page_look !== 'mva' && (
            <FormErrorBox
              messageClass="form-error"
              error={text(state.errorObj.msg)}
              iconType={`icon_${state.errorObj.type}`}
              messageType={`message_${state.errorObj.type}`}
            />
          )}
          {state.isError && state.errorObj && state.errorObj.msg && state.page_look === 'mva' && (
            <Error text={text(state.errorObj.msg)} />
          )}

          <form noValidate className={context}>
            <div className={`section-form-panel ${inputErr}`}>
              <SectionTitle className={titleLogo} title={title} subTitle={subTitle}></SectionTitle>

              {backendMsg}
              {contextRender}
            </div>
          </form>
        </div>
      </div>

      <Footer showTncFooter={state.terms} showPPFooter={state.privacy} />

      {sessionTimer}
    </div>
  );
};

export default AuthorizeMain;
