import React, { useContext, useState, useEffect } from "react";
import { text } from "../../utils/global";

// Translations
import { StoreContext } from "../../store/store";

// Action types
import { BANNER, POPUP } from "../../constants/actionTypes";
import { getTopDomainName } from "../../utils/utils";

import PopUp from "../PopUp/PopUp";
import PopUpHeader from "../PopUp/PopUpHeader";
import PopUpContent from "../PopUp/PopUpContent";
import PopUpFooter from "../PopUp/PopUpFooter";
import Button from "../Button/Button";
import Link from "../Link/Link";

import {Tabs, Tab} from "../Tabs/Tabs";
import Toggle from "../Toggle/Toggle";
import Cookies from 'js-cookie';

// Stying
import "./manage-cookies.scss";
import useSMAPI from "../SMAPI/useSMAPI";
import analyticsTealium from "../../utils/Analytics/analytics";
import cookieConsent from "../../utils/cookieConsent";
import VFLogger from "../../utils/Logger";

const ManageCookies = (props) => {
  const { toggleSMAPI } = useSMAPI();
  const globalState = useContext(StoreContext);
  const { dispatch } = globalState;
  const {getCookieConsent, checkShowBanner}= cookieConsent();

  // Translations
  const tabTitle1 = text("cookieManager.aboutCookiePopup.category.c1");
  const tabTitle2 = text("cookieManager.aboutCookiePopup.category.c2");
  const tabTitle3 = text("cookieManager.aboutCookiePopup.category.c3");
  const tabTitle4 = text("cookieManager.aboutCookiePopup.category.c4");
  const tabTitle5 = text("cookieManager.aboutCookiePopup.category.c5");

  const tabContent1 = text(
    "cookieManager.aboutCookiePopup.instructionCategory1"
  );
  const tabContent2 = text(
    "cookieManager.aboutCookiePopup.instructionCategory2"
  );
  const tabContent3 = text(
    "cookieManager.aboutCookiePopup.instructionCategory3"
  );
  const tabContent4 = text(
    "cookieManager.aboutCookiePopup.instructionCategory4"
  );
  const tabContent5 = text(
    "cookieManager.aboutCookiePopup.instructionCategory5"
  );

  let buttonSaveSettings = text("cookieManager.aboutCookiePopup.saveSettingsBtn");
  const headerTitle = text("cookieManager.aboutCookiePopup.subTitle");
  const footerTitle = text("cookieManager.aboutCookiePopup.titleFooter");

  const strictlyNecessaryCookiesStatusAlwaysActive = text("cookieManager.aboutCookiePopup.status.always");

  const activeStatusText = text("cookieManager.aboutCookiePopup.status.active");
  const notActiveStatusText = text("cookieManager.aboutCookiePopup.status.notActive");

  const cookiesUsedTitleText = text("cookieManager.aboutCookiePopup.cookieUse");

  // SMAPI
  const [performanceCookiesAllowed, setPerformanceCookiesAllowed] = useState(false);

  //Markenting
  const [marketingCookiesAllowed, setMarketingCookiesAllowed] = useState(false);
  const [functionalCookiesAllowed, setFunctionalCookiesAllowed] = useState(false);
  
  const { setTealiumActive } = analyticsTealium();

  useEffect(() => {
    VFLogger.info('Manage cookies: loaded')
    //Setting toggle values
    if(window.config.smapiEnabled){
      setPerformanceCookiesAllowed(getCookieConsent('performance'));
    }
    if(window.config.cookieManagement.functionalCookiesEnabled){
      setFunctionalCookiesAllowed(getCookieConsent('functional'));
    }
    if( window.config.analytics && window.config.analytics.tealium_enabled){
      setMarketingCookiesAllowed(getCookieConsent('marketing'));
    }
  }, []);

  const closePopUp = () => {
    VFLogger.info('Manage cookies: unloaded')
    dispatch({
      type: POPUP,
      banner: false
    });
  };

  const closePopUpAndOpenCookieBanner = () => {
    dispatch({
      type: BANNER,
      banner: checkShowBanner()
    });
  };

  const handleCookieToggle = (id) => {
    if(id == 'performance') {
      VFLogger.info(`Manage cookies: ${id} cookies toggled to ${!performanceCookiesAllowed}`)
      setPerformanceCookiesAllowed(!performanceCookiesAllowed);
    }
    if(id == 'marketing') {
      VFLogger.info(`Manage cookies: ${id} cookies toggled to ${!marketingCookiesAllowed}`)
      setMarketingCookiesAllowed(!marketingCookiesAllowed);
    }

    if(id == 'functional') {
      VFLogger.info(`Manage cookies: ${id} cookies toggled to ${!functionalCookiesAllowed}`)
      setFunctionalCookiesAllowed(!functionalCookiesAllowed);
    }

  };

  const saveSettings = () => {
    /*Save the cookies in the top domain*/

    let consent = {};
    try {
        const consentCookieRawValue = Cookies.get("consent");

        if (consentCookieRawValue) {
            const consentDecodedValue = window.atob(consentCookieRawValue)
            consent = JSON.parse(consentDecodedValue);
        }
    } catch (err) {
        console.warn("Can't read consent setting. Consent was reset");
        console.error(err);
    }

    let cookies = {};

    //Add Necessary cookie list
    const necessaryCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.necessary").split(", ");
    if(necessaryCookieList){

        necessaryCookieList.map((cookie) => {
          cookies[cookie] = true;
        });

        let cookieDetails = {
          on: new Date()
        };

        if(consent['necessary']) {
            cookieDetails["cookies"] =  { ...consent['necessary'].cookies, ...cookies};
        }else{
            cookieDetails["cookies"] =  cookies;
        }

        consent['necessary'] = cookieDetails;
    }

    //Add functional cookie list
    cookies = {};
    const functionalCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.functional").split(", ");
    if(functionalCookieList && window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled){

      functionalCookieList.map((cookie) => {
          cookies[cookie] = functionalCookiesAllowed;
          if(!functionalCookiesAllowed){
            document.cookie = cookie + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          }
        });

        let cookieDetails = {
          on: new Date()
        };

        if(consent['functional']) {
            cookieDetails["cookies"] =  { ...consent['functional'].cookies, ...cookies};
        }else{
            cookieDetails["cookies"] =  cookies;
        }

        consent['functional'] = cookieDetails;
    }


    cookies = {};
    //Add Performance cookie list (smapi)
    const performanceCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.optional").split(", ");
    if(performanceCookieList && window.config.smapiEnabled){
        performanceCookieList.map((cookie) => {
            cookies[cookie] = performanceCookiesAllowed;

            if(!performanceCookiesAllowed){
              if(sessionStorage.getItem(cookie)){
                sessionStorage.removeItem(cookie);
              }else{
                document.cookie = cookie + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              }
            }
        });

        let cookieDetails = {
          on: new Date()
        };

        if(consent['performance']) {
            cookieDetails["cookies"] =  { ...consent['performance'].cookies, ...cookies};
        }else{
            cookieDetails["cookies"] =  cookies;
        }

        consent['performance'] = cookieDetails;
        //ON / OFF the smapi
        toggleSMAPI(performanceCookiesAllowed);
    }

    cookies = {};
    //Add marketing cookie list translation (tealium)
    const marketingCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.marketing").split(", ");
    if(marketingCookieList.length > 0 && window.config.analytics && window.config.analytics.tealium_enabled){
        marketingCookieList.map((cookie) => {
              cookies[cookie] = marketingCookiesAllowed;

              if(!marketingCookiesAllowed){
                document.cookie = cookie + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + getTopDomainName();
              }
        });

        let cookieDetails = {
            on: new Date()
        };

        if(consent['marketing']) {
            cookieDetails["cookies"] =  { ...consent['marketing'].cookies, ...cookies};
        }else{
            cookieDetails["cookies"] =  cookies;
        }

        consent['marketing'] = cookieDetails;
        //ON / OFF the marketing
        setTealiumActive(marketingCookiesAllowed);
    }

    /*Remove and Create the cookie consent */
    Cookies.remove("consent");
    const consentEncoded = window.btoa(JSON.stringify(consent));
    Cookies.set("consent", consentEncoded, { path: '/', domain: getTopDomainName(), expires: 183 });
    /* Close the popup */
    closePopUp();
  };

  const showPrivacyPolicy = () => {
    dispatch({
      type: POPUP,
      popup: "COOKIE_POLICY"
    });
  };

  if(!window.config.smapiEnabled && window.config.analytics && !window.config.analytics.tealium_enabled){
    buttonSaveSettings = text('cookieManager.cookieBanner.dismiss');
  }

  return (
    <PopUp onClickOutSide={closePopUpAndOpenCookieBanner}>
      <PopUpHeader onCloseButtonClick={closePopUpAndOpenCookieBanner}>
        <span>{headerTitle}</span>
      </PopUpHeader>

      <PopUpContent>
          <Tabs>
            <Tab title={tabTitle1} icon={"how_to_use"}>
              <p>{tabContent1}</p>
            </Tab>
            <Tab title={tabTitle2} icon={"necessary_cookies"}>
              <>
                <div className="status">
                  <span className="success">
                    {strictlyNecessaryCookiesStatusAlwaysActive}
                  </span>
                </div>
                <p>{tabContent2}</p>
                <br />
                <p>
                  <strong>{cookiesUsedTitleText}</strong>
                </p>
                <hr />
                <strong>{text("cookieManager.aboutCookiePopup.cookieUseList.necessary")}</strong>
                <hr />
              </>
            </Tab>

            {window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled ?
            (<Tab title={tabTitle3} icon={"functional_cookies"}>
              <>
                <div className="status">
                  <Toggle
                    labelOn={activeStatusText}
                    labelOff={notActiveStatusText}
                    checked={functionalCookiesAllowed}
                    onHandleClick={() => handleCookieToggle("functional")}
                  />
                </div>
                <p>{tabContent3}</p>
                <br />
                <hr />
                <p>
                  <strong>
                    {text("cookieManager.aboutCookiePopup.cookieUseList.functional")}
                  </strong>
                </p>
                <hr />
              </>
            </Tab>): ""}

            {window.config.smapiEnabled ?
            (<Tab title={tabTitle4} icon={"functional_cookies"}>
              <>
                <div className="status">
                  <Toggle
                    labelOn={activeStatusText}
                    labelOff={notActiveStatusText}
                    checked={performanceCookiesAllowed}
                    onHandleClick={() => handleCookieToggle("performance")}
                  />
                </div>
                <p>{tabContent4}</p>
                <br />
                <hr />
                <p>
                  <strong>
                    {text("cookieManager.aboutCookiePopup.cookieUseList.optional")}
                  </strong>
                </p>
                <hr />
              </>
            </Tab>): ""}

            {window.config.analytics && window.config.analytics.tealium_enabled
              ? (<Tab title={tabTitle5} icon={"functional_cookies"}>
              <>
                <div className="status">
                  <Toggle
                    labelOn={activeStatusText}
                    labelOff={notActiveStatusText}
                    checked={marketingCookiesAllowed}
                    onHandleClick={() => handleCookieToggle('marketing')}
                  />
                </div>
                <p>{tabContent5}</p>
                <br />
                <hr />
                <p>
                  <strong>
                    {text("cookieManager.aboutCookiePopup.cookieUseList.marketing")}
                  </strong>
                </p>
                <hr />
              </>
            </Tab>): ""}

        </Tabs>

      </PopUpContent>

      <PopUpFooter>
        <div className="footer-links">
          <span>{footerTitle}</span>
          <Link label={"Cookie Policy"} handleLink={() => showPrivacyPolicy()} />
        </div>

        <Button
          type={"primary"}
          label={buttonSaveSettings}
          handleClick={saveSettings}
        />
      </PopUpFooter>
    </PopUp>
  );
};

export default ManageCookies;
