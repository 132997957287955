import React, { useEffect, useContext, useState } from 'react';
import { StoreContext } from '../../store/store';
import Spinner from '../Spinner/Spinner';
import VFLogger from '../../utils/Logger';
import analyticsTealium from '../../utils/Analytics/analytics';
import { getPageName } from '../../constants/tealiumMapper';

const Follow = (props) => {
  const { state } = useContext(StoreContext);
  const { trackPageView } = analyticsTealium();
  useEffect(() => {
    if (
      state.data &&
      state.data.links &&
      state.data.links['urn:vodafoneid:follow']
    ) {
      trackPageView(getPageName(state.data.context), { flowName: 'login', flowType: 'login' });
      VFLogger.info('FOLLOW: process url: ' + state.data.links['urn:vodafoneid:follow'].href);
      window.submitter.flushBufferedEvents().then((res) => {
        console.log('events flushed properly')
      })
      .catch(e=> {
        console.log('failed to send smapi event in follow request', e)
      })
      
      window.location.href = state.data.links['urn:vodafoneid:follow'].href;
    }

  }, []);
  return <Spinner />;
};
export default Follow;
